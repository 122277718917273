// @flow
import React from 'react';
import { useRouter } from 'next/router';

import Loading from 'components/fragments/Loading';
import { STATUS_LOADING } from 'constants/statusConstants';
import ChooseKitFormats from 'containers/kits/ChooseKitFormatsContainer';
import type { SingleMyKit } from 'types/Kits';
import type { Status } from 'types/Status';

export type DispatchProps = {
	deleteKitVariation(id: number, structureid: number, kitorig: number): void,
};

export type StateProps = {
	postKitVariationStatus: Status,
	singleMyKit: SingleMyKit,
	structureid: number,
};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

function ChooseNewKitFormatsModalContent({
	content,
	deleteKitVariation,
	hideModal,
	postKitVariationStatus,
	singleMyKit,
	structureid,
}: Props): React$Element<'div'> {
	const router = useRouter();
	const isEditorRoute = router.asPath.includes('editeur');

	const handleCloseModal = () => {
		if (!isEditorRoute) {
			deleteKitVariation(singleMyKit.id, structureid, parseInt(singleMyKit.kitorig));
		}

		hideModal();
	};

	return (
		<div className="modal-content box modal--kit">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleCloseModal}></i>

			<div className="modal__wrapper">
				{!isEditorRoute && postKitVariationStatus === STATUS_LOADING ? (
					<Loading isWhite />
				) : (
					<ChooseKitFormats content={content} hideModal={hideModal} />
				)}
			</div>
		</div>
	);
}

export default ChooseNewKitFormatsModalContent;
