// @flow
import { connect } from 'react-redux';

import { deleteKitVariation, resetDeleteKitVariationStatus } from 'actions/kitsVariationsActions';
import type { StateProps, DispatchProps } from 'components/kits/kitCard/DeleteKit';
import DeleteKit from 'components/kits/kitCard/DeleteKit';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		structureid: state.magicLinkState.structureID,
		deleteKitVariationStatus: state.kitsVariationsState.deleteKitVariationStatus,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		deleteKitVariation: (id: number, structureid: number, kitorig: number) =>
			dispatch(deleteKitVariation(id, structureid, kitorig)),
		resetDeleteKitVariationStatus: () => dispatch(resetDeleteKitVariationStatus()),
	}),
)(DeleteKit): any);
