/* eslint-disable import/no-anonymous-default-export */
// @flow

import CookieCutter from 'cookie-cutter';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
	DELETE_KIT_VARIATION,
	deleteKitVariationSuccess,
	deleteKitVariationError,
	FETCH_KITS_VARIATIONS_LIST_START,
	kitsVariationsFetched,
	kitsVariationsFetchError,
	FETCH_KITS_BY_STRUCTURE_START,
	kitsByStructureFetched,
	kitsByStructureFetchError,
	FETCH_SINGLE_KIT_VARIATION_START,
	singleKitVariationFetched,
	singleKitVariationFetchError,
	POST_KIT_VARIATION,
	postKitVariationSuccess,
	postKitVariationError,
} from 'actions/kitsVariationsActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import { displayModal } from 'actions/modalActions';
import type {
	DELETE_KIT_VARIATION_ACTION,
	FETCH_KITS_VARIATIONS_LIST_START_ACTION,
	FETCH_KITS_BY_STRUCTURE_START_ACTION,
	FETCH_SINGLE_KIT_VARIATION_START_ACTION,
	POST_KIT_VARIATION_ACTION,
} from 'actions/kitsVariationsActions';
import KitsVariationsApi from 'api/kitsVariations/KitsVariationsApi';
import type { GeneratorType } from 'sagas/root';
import { convertMyKitListFromApi, convertSingleKitVariationFromApi } from 'services/KitsVariations';
import type { ApiExecutorType } from 'types/ApiExecutorType';

const content = {
	title: 'Votre code a expiré.',
};

export default function (onBoardingApiExecutor: ApiExecutorType): () => GeneratorType {
	const kitsVariationsApi = new KitsVariationsApi(onBoardingApiExecutor);
	const getMagicLinkInState = (state) => state.magicLinkState;

	return function* kitsVariationsSaga(): GeneratorType {
		yield takeLatest(DELETE_KIT_VARIATION, deleteKitVariation);
		yield takeLatest(FETCH_KITS_VARIATIONS_LIST_START, fetchKitsVariationsApi);
		yield takeLatest(FETCH_KITS_BY_STRUCTURE_START, fetchKitsByStructure);
		yield takeLatest(FETCH_SINGLE_KIT_VARIATION_START, fetchSingleKitVariation);
		yield takeLatest(POST_KIT_VARIATION, postKitVariation);

		function* deleteKitVariation(action: DELETE_KIT_VARIATION_ACTION) {
			const { magicLink } = yield select(getMagicLinkInState);
			const { id, kitorig, structureid } = action.payload;

			try {
				yield call(kitsVariationsApi.deleteKitVariation, id, structureid, kitorig, magicLink);

				yield put(deleteKitVariationSuccess());
			} catch (e) {
				// ! @todo - erreur à traiter : rest_invalid_kitorig

				if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
					yield put(displayModal('RE_ASK_MAGIC_LINK', content));
					CookieCutter.set('magic', '', { expires: new Date(0) });
					yield put(forgotMagicLink());
				}

				yield put(deleteKitVariationError(e.message));
			}
		}

		function* fetchKitsVariationsApi(action: FETCH_KITS_VARIATIONS_LIST_START_ACTION) {
			const { magicLink } = yield select(getMagicLinkInState);
			const { structureid } = action.payload;

			try {
				const myKitList = yield call(kitsVariationsApi.fetchKitsVariations, structureid, magicLink);

				const myKitListSortedByDate = !!myKitList.length
					? myKitList.sort((a, b) =>
							new Date(a.date) < new Date(b.date) ? -1 : new Date(a.date) > new Date(b.date) ? 1 : 0,
					  )
					: [];

				yield put(kitsVariationsFetched(convertMyKitListFromApi(myKitListSortedByDate)));
			} catch (e) {
				if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
					yield put(displayModal('RE_ASK_MAGIC_LINK', content));
				}

				yield put(kitsVariationsFetchError(e.message));
			}
		}

		function* fetchKitsByStructure(action: FETCH_KITS_BY_STRUCTURE_START_ACTION) {
			const { magicLink } = yield select(getMagicLinkInState);
			const { structureid, kitadmin, userstructureid } = action.payload;

			try {
				const myKitList = yield call(
					kitsVariationsApi.fetchKitsByStructure,
					structureid,
					magicLink,
					kitadmin,
					userstructureid,
				);

				const myKitListSortedByDate = !!myKitList.length
					? myKitList.sort((a, b) =>
							new Date(a.date) < new Date(b.date) ? -1 : new Date(a.date) > new Date(b.date) ? 1 : 0,
					  )
					: [];

				yield put(kitsByStructureFetched(convertMyKitListFromApi(myKitListSortedByDate)));
			} catch (e) {
				if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
					yield put(displayModal('RE_ASK_MAGIC_LINK', content));
				}

				yield put(kitsByStructureFetchError(e.message));
			}
		}

		function* fetchSingleKitVariation(action: FETCH_SINGLE_KIT_VARIATION_START_ACTION): Saga<void> {
			const { magicLink } = yield select(getMagicLinkInState);
			const { id, kitorig, structureid } = action.payload;

			try {
				const singleMyKit = yield call(kitsVariationsApi.fetchSingleKitVariation, id, structureid, magicLink);

				yield put(singleKitVariationFetched(convertSingleKitVariationFromApi(singleMyKit)));
			} catch (e) {
				if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
					yield put(displayModal('RE_ASK_MAGIC_LINK', content));
				}

				yield put(singleKitVariationFetchError(e.message));
			}
		}

		function* postKitVariation(action: POST_KIT_VARIATION_ACTION): Saga<void> {
			const { magicLink } = yield select(getMagicLinkInState);
			const { change, id, kitorig, structureid } = action.payload;

			try {
				const newVariation = yield call(
					kitsVariationsApi.postKitVariation,
					structureid,
					kitorig,
					magicLink,
					id,
					change,
				);
				yield put(postKitVariationSuccess(convertSingleKitVariationFromApi(newVariation)));
			} catch (e) {
				if (
					e.response &&
					e.response.data &&
					e.response.data.code &&
					(e.response.data.code === 'rest_invalid_magic_code' || e.response.data.code === 'structureid') &&
					window.location.pathname === '/'
				) {
					yield put(displayModal('RE_ASK_MAGIC_LINK', content));
				}

				console.error('L’enregistrement n’a pas pu s’effectuer');
				yield put(postKitVariationError(e.message));
			}
		}
	};
}
