// @flow

import CookieCutter from 'cookie-cutter';
import React, { PureComponent } from 'react';

import Button from 'components/fragments/button/Button';
import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';
import { validateEmail } from 'utils/validatorUtils';
import TagManager from 'utils/tagManagerUtils';

export type DispatchProps = {
	askMagicLink: (email: string) => void,
};

type Props = DispatchProps;

type State = {
	email: string,
};

export default class CreationRequestForm extends PureComponent<Props, State> {
	state: State = {
		email: '',
	};

	handleChange: (value: string) => void = (value: string) => {
		this.setState((prevState) => ({
			...prevState,
			email: value,
		}));
	};

	handleSubmit: () => void = () => {
		const {
			state: { email },
			props: { askMagicLink },
		} = this;

		TagManager.handleTagEvent({
			event: 'email_verification_requested',
		});

		if (CookieCutter.get('magic')) {
			CookieCutter.set('magic', ``, { expires: new Date(0) });
		}

		askMagicLink(email);
	};

	render(): React$Element<React$FragmentType> {
		const {
			handleChange,
			handleSubmit,
			state: { email },
		} = this;

		return (
			<>
				<div className="modal__body modal__body--medium mb-3 mb-md-5">
					<p className="mb-2">
						Pour accéder à la plateforme de création des kits de communication, nous devons dans un premier temps
						vérifier que vous appartenez à une structure affiliée à la Fédération Française de Rugby.
					</p>
					<p className="ft-700 mb-2">
						Renseignez l’adresse email officielle sur laquelle les emails de la Fédération sont envoyés. Vous recevrez
						alors un email avec toutes les informations nécessaires pour vous connecter à la plateforme.
					</p>
				</div>
				<TextInputMaterial
					id="YsfabyzSgwsw"
					label="Adresse e-mail code club (ex : 6082T@ffr.fr)*"
					className="mb-3"
					hasError={!validateEmail(email) && email !== ''}
					errorMessage="Votre e-mail n'est pas valide"
					onChange={handleChange}
				/>
				<Button label="Confirmer mon adresse mail" isDisabled={!validateEmail(email)} onClick={handleSubmit} isFull />
			</>
		);
	}
}
