/* eslint-disable @next/next/no-img-element */
// @flow
import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';

import Button from 'components/fragments/button/Button';
import Loading from 'components/fragments/Loading';
import SingleFormatCard from 'components/kits/formatCard/SingleFormatCard';
import { FORMAT_LIST } from 'constants/kitConstants';
import { RTGENV } from 'constants/numberlyConstants';
import { STATUS_LOADING } from 'constants/statusConstants';
import Numberly from 'tracking/numberly';
import type { SingleMyKit } from 'types/Kits';
import type { RouterProps } from 'types/Router';
import type { Status } from 'types/Status';
import TagManager from 'utils/tagManagerUtils';
import { capitalizeFirstLetter } from 'utils/textUtils';

export type DispatchProps = {
	postKitVariation(structureid: number, kitorig: number, id?: number, change?: any): void,
};

export type StateProps = {
	postKitVariationStatus: Status,
	singleMyKit: SingleMyKit,
	structureid: number,
};

type Props = DispatchProps &
	RouterProps &
	StateProps & {
		content: any,
		hideModal(): void,
	};

type State = {
	selectedFormats: string[],
	selectedColor: string,
	hasColorChanged: boolean,
	displayColorPicker: boolean,
};

function ChooseKitFormats({
	content,
	hideModal,
	postKitVariation,
	postKitVariationStatus,
	router,
	singleMyKit,
	structureid,
}: Props) {
	const [formats, setFormats] = useState();
	const [selectedFormats, setSelectedFormats] = useState([]);
	const [selectedColor, setSelectedColor] = useState('#002a61');
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [hasColorChanged, setHasColorChanged] = useState(false);

	const [prevSelectedFormat, setPrevSelectedFormat] = useState([]);
	const [errorMessage, setErrorMessage] = useState();
	const isEditorRoute = router.pathname.includes('editeur');

	const popover = {
		position: 'absolute',
		zIndex: '2',
		left: '80px',
		bottom: '0px',
	};
	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	};

	useEffect(() => {
		getSelectedFormats();
		getAvailableFormats();
		getSelectedCouleur();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//==========================================================================
	// Variables
	//==========================================================================
	const currentKitFormats = formats
		? formats
				.filter((format) => !!format.link || !!format.exemple)
				.map((format) => {
					const newCurrentKitFormat = FORMAT_LIST.find((item) => item.id === format.name);

					return newCurrentKitFormat;
				})
		: FORMAT_LIST;
	const webFormats = currentKitFormats.filter((format) => format.type === 'web');
	const printFormats = currentKitFormats.filter((format) => format.type === 'print');

	//==========================================================================
	// Fonctions spécifiques
	//==========================================================================
	const getAvailableFormats = () => {
		const formats = isEditorRoute
			? content.kit.formats_orig.filter((formatOrig) => !content.kit.formats.includes(formatOrig.name))
			: content.kit.formats;

		setFormats(formats);
	};

	const handleCloseColor = () => {
		setDisplayColorPicker(false);
	};

	const handleClickColor = () => {
		setDisplayColorPicker(!displayColorPicker);
	};

	const onChangeSelectedColor = (color) => {
		setSelectedColor(color.hex);
		setHasColorChanged(true);
	};

	const getSelectedFormats = () => {
		let alreadySelectedFormats;
		if (isEditorRoute) {
			alreadySelectedFormats = content.kit.formats_selected.map((format) => format.name);
		} else {
			alreadySelectedFormats = content.kit.formats.filter((format) => !!format.link).map((format) => format.name);
			alreadySelectedFormats = [alreadySelectedFormats[0]];
		}
		setSelectedFormats(alreadySelectedFormats);
		setPrevSelectedFormat(alreadySelectedFormats);
	};

	const getSelectedCouleur = () => {
		if (content && content.kit && content.kit.color) {
			setSelectedColor(content.kit.color);
		}
	};

	const handleErrorMessage = (newSelectedFormats) => {
		const prevSelectedFormatsNumber = prevSelectedFormat.length;

		if (isEditorRoute) {
			const newSelectedFormatsNumber = newSelectedFormats.length;

			if (newSelectedFormatsNumber < prevSelectedFormatsNumber) {
				setErrorMessage('Merci de sélectionner au minimum un format.');
			} else {
				setErrorMessage();
			}
		} else {
			if (!newSelectedFormats.length) {
				setErrorMessage('Merci de sélectionner au minimum un format.');
			} else {
				setErrorMessage();
			}
		}
	};

	const handlePostFormats = () => {
		const kitid = singleMyKit.id;
		const kitorig = parseInt(singleMyKit.kitorig, 10);

		if (selectedFormats.length > 0) {
			let options = { formats: selectedFormats };

			// ajouter la couleur si c'est un kit personnalisable transparent
			if (!!content.kit.type && content.kit.type === 'personnalisable_transparent') {
				options = { formats: selectedFormats, color: selectedColor };
			}

			postKitVariation(structureid, kitorig, kitid, options );
			hideModal();
		} else {
			setErrorMessage('Merci de sélectionner au minimum un format.');
		}

		if (!isEditorRoute) {
			const kitPurpose = selectedFormats
				.reduce((acc, selectedFormat) => {
					const currentFormat = FORMAT_LIST.find((format) => format.id === selectedFormat);
					const currentFormatType = !!currentFormat && !!currentFormat.type ? currentFormat.type : '';

					if (!!currentFormatType && acc.every((item) => item !== currentFormatType)) {
						return acc.concat(currentFormatType);
					}

					return acc;
				}, [])
				.join('|');

			const kitFormats = FORMAT_LIST.map((format) => {
				if (selectedFormats.includes(format.id)) {
					return format.name;
				}
			})
				.filter((format) => !!format)
				.join('|');

			TagManager.handleTagEvent({
				event: 'kit_customization_initiated',
				kit_type: content.kit.type,
				kit_purpose: kitPurpose ?? '',
				kit_theme: content.kit.tag.join('|') !== '' ? content.kit.tag.join('|') : 'null',
				kit_name: content.kit.title,
				kit_format: kitFormats,
				kit_color: !!content.kit.kit_color ? content.kit.kit_color : 'null',
				page_path: router.asPath,
				page_title: document.title,
			});

			Numberly.tag({
				rtgpg: 'PersonalizeKit',
				rtgenv: RTGENV,
				rtglogged: '1', // car ici on est forcément connecté
				rgtconversion: '1',
				rtglanguage: 'fr',
			});
		}
	};

	const onSingleFormatCardClick = (formatId: string) => {
		let newSelectedFormats = selectedFormats;

		if (!selectedFormats.includes(formatId)) {
			newSelectedFormats = selectedFormats.concat(formatId);
		} else {
			newSelectedFormats = selectedFormats.filter((format) => format !== formatId);
		}

		setSelectedFormats(newSelectedFormats);

		handleErrorMessage(newSelectedFormats);
	};

	//==========================================================================
	// Render
	//==========================================================================
	if (postKitVariationStatus === STATUS_LOADING) {
		return <Loading isWhite />;
	}

	return (
		<>
			{content && content.kit && content.kit.medium && (
				<div className="modal--kit__preview">
					<div>
						<img src={content.kit.medium} alt={content.kit.title} />
						<p className="mt-2">
							{!!content.kit.tag.length ? `${capitalizeFirstLetter(content.kit.tag[0])} -` : ''} {content.kit.title}
						</p>
					</div>
				</div>
			)}
			<div className="modal--kit__content">
				<div className="modal__body modal__body--medium narrow-2">
					<h3 className="mb-1 mt-2 ft-h2">{isEditorRoute ? 'Ajout de format' : 'Choix des formats à personnaliser'}</h3>
					{currentKitFormats && !!currentKitFormats.length && (
						<p className="mb-1">{`Choisissez les formats que vous souhaitez ${
							isEditorRoute ? 'ajouter à' : 'modifier pour'
						} votre kit personnalisé.`}</p>
					)}

					{/* Plus de format disponible */}
					{(!currentKitFormats || !currentKitFormats.length) && (
						<p className="ft-h2 mt-3 mb-1">Aucun format disponible</p>
					)}
				</div>

				{/* Formats disponibles */}
				{!!webFormats.length && (
					<div>
						<h4 className="ft-h6 ft-up mb-1">Formats web</h4>
						<div className="format-list">
							{webFormats.map((format) => (
								<SingleFormatCard
									key={format.id}
									format={format}
									onSingleFormatCardClick={onSingleFormatCardClick}
									isSelected={selectedFormats.includes(format.id)}
								/>
							))}
						</div>
					</div>
				)}

				{!!printFormats.length && (
					<div>
						<h4 className="ft-h6 ft-up mb-1">Formats print</h4>
						<div className="format-list">
							{printFormats.map((format) => (
								<SingleFormatCard
									key={format.id}
									format={format}
									onSingleFormatCardClick={onSingleFormatCardClick}
									isSelected={selectedFormats.includes(format.id)}
								/>
							))}
						</div>
					</div>
				)}
				{content && content.kit && content.kit.type === 'personnalisable_transparent' && (
					<>
						<div className="modal__body modal__body--medium narrow-2">
							<h3 className="mb-1 mt-2 ft-h2">{'Choix de la couleur principale'}</h3>
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ position: 'relative' }}>
								<button className={'color'} style={{ backgroundColor: selectedColor }} onClick={handleClickColor} />
								{displayColorPicker ? (
									<div style={popover}>
										<div style={cover} onClick={handleCloseColor} />
										<SketchPicker
											width="168px"
											presetColors={['#002a61', '#E21F20', '#B60718', '#FFFFFF', '#666666', '#ABB8C3', '#000000']}
											color={selectedColor}
											onChange={onChangeSelectedColor}
										/>
									</div>
								) : null}
							</div>
							<p style={{ margin: '0 0 0 10px' }}>Cliquez pour modifier la couleur principale</p>
						</div>
					</>
				)}

				<div>
					{errorMessage ? (
						<div className="mb-2 mt-1 mt-md-2 btn btn--white error">{errorMessage}</div>
					) : !isEditorRoute && currentKitFormats && currentKitFormats.length ? (
						<Link href="/editeur">
							<a className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={handlePostFormats}>
								Commencer la personnalisation
							</a>
						</Link>
					) : selectedFormats && selectedFormats.length && prevSelectedFormat.length !== selectedFormats.length ? (
						<button className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={handlePostFormats}>
							Ajouter les formats
						</button>
					) : hasColorChanged ? (
						<button className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={handlePostFormats}>
							Modifier la couleur
						</button>
					) : (
						<button className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={hideModal}>
							Fermer
						</button>
					)}
				</div>
			</div>
		</>
	);
}

export default (withRouter(ChooseKitFormats): any);
