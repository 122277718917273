// @flow
import { connect } from 'react-redux';

import { postKitVariation, resetPostKitVariationStatus } from 'actions/kitsVariationsActions';
import type { StateProps, DispatchProps } from 'components/editor/RemoveKitFormat';
import RemoveKitFormat from 'components/editor/RemoveKitFormat';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		postKitVariationStatus: state.kitsVariationsState.postKitVariationStatus,
		structureid: state.magicLinkState.structureID,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		postKitVariation: (structureid: number, kitorig: number, id: number, change: Object) =>
			dispatch(postKitVariation(structureid, kitorig, id, change)),
	}),
)(RemoveKitFormat): any);
