// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function KitsVariationsApi(executor: ApiExecutorType): {
	deleteKitVariation: (id: number, structureid: number, kitorig: number, magicLink: string) => empty,
	fetchKitsVariations: (structureid: number, magicLink: string) => empty,
	fetchKitsByStructure: (structureid: number, magicLink: string, kitadmin: boolean, userstructureid: number) => empty,
	fetchSingleKitVariation: (id: number, structureid: number, magicLink: string) => empty,
	postKitVariation: (structureid: number, kitorig: number, magicLink: string, id?: number, change?: any) => empty,
	...
} {
	return { deleteKitVariation, fetchKitsVariations, fetchKitsByStructure, fetchSingleKitVariation, postKitVariation };

	function deleteKitVariation(id: number, structureid: number, kitorig: number, magicLink: string) {
		return executor.del(
			`ffr/v1/kit_variation/${id}?structureid=${structureid}&kitorig=${kitorig}`,
			getOnboardingMagicHeader(magicLink),
		);
	}

	function fetchKitsVariations(structureid: number, magicLink: string) {
		return executor.get(
			`ffr/v1/kit_variation?structureid=${structureid}&time=${new Date().getTime()}`,
			getOnboardingMagicHeader(magicLink),
		);
	}

	function fetchKitsByStructure(structureid: number, magicLink: string, kitadmin: boolean, userstructureid: number) {
		let url = `ffr/v1/kit_variation?structureid=${structureid}&time=${new Date().getTime()}`;

		if (!!kitadmin) {
			url = `${url}&kitadmin=true`;
		}
		if (!!userstructureid) {
			url = `${url}&userstructureid=${userstructureid}`;
		}

		return executor.get(url, getOnboardingMagicHeader(magicLink));
	}

	function fetchSingleKitVariation(id: number, structureid: number, magicLink: string) {
		return executor.get(
			`ffr/v1/kit_variation/${id}?structureid=${structureid}&time=${new Date().getTime()}`,
			getOnboardingMagicHeader(magicLink),
		);
	}

	function postKitVariation(structureid: number, kitorig: number, magicLink: string, id?: number, change?: any) {
		let url = `ffr/v1/kit_variation`;
		if (!!id) {
			url = `${url}/${id}`;
		}

		return executor.post(url, { structureid, kitorig, ...change }, getOnboardingMagicHeader(magicLink));
	}
}

export default KitsVariationsApi;
